<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row" v-if="patientModel.ID == null || patientModel.ID == undefined">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Email") }}</label>
        <vs-input
          v-model="patientModel.Email"
          class="w-full"
          name="Email"
          v-validate="'email'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Email')">{{
          errors.first("Email")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Password") }}</label>
        <vs-input
          v-model="patientModel.Password"
          class="w-full"
          name="Password"
          type="password"
          v-validate="'Password'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Password')">{{
          errors.first("Password")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <vs-checkbox class="mt-5" v-model="patientModel.IsActive"
          >{{ $t("IsActive") }}
        </vs-checkbox>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <vs-checkbox class="mt-5" v-model="patientModel.IsConfirmed"
          >{{ $t("IsConfirmed") }}
        </vs-checkbox>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("NameEn") }}</label>
        <vs-input v-model="patientModel.NameEN" class="w-full" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('FullNameEN')">{{
          errors.first("FullNameEN")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("NameAr") }}</label>
        <vs-input
          v-model="patientModel.NameAR"
          v-validate="'required'"
          class="w-full"
          name="FullNameAR"
        />
        <span class="text-danger text-sm" v-show="errors.has('FullNameAR')">{{
          errors.first("FullNameAR")
        }}</span>
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Country") }}</label>
        <v-select
          label="Name"
          name="Country"
          class="mw-full"
          v-model="patientModel.CountryID"
          :options="countries"
          :reduce="(ID) => ID.ID"
          @input="getRegionByCountryId(patientModel.CountryID)"
        />
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("City") }}</label>
        <v-select
          label="Name"
          name="City"
          class="mw-full"
          v-model="patientModel.CityID"
          :options="cities"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Region") }}</label>
        <v-select
          label="Name"
          name="Gender"
          class="mw-full"
          v-model="patientModel.RegionID"
          :options="regions"
          :reduce="(ID) => ID.ID"
          @input="getCityByRegionId(patientModel.RegionID)"
        />
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Address") }}</label>
        <vs-input v-model="patientModel.Address" class="w-full" name="Telephone" />
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Gender") }}</label>
        <v-select
          label="Name"
          name="Gender"
          class="mw-full"
          v-model="patientModel.GenderID"
          :options="genders"
          :reduce="(ID) => ID.ID"
        />
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Num of children") }}</label>
        <vs-input
          v-model="patientModel.NumOfChildren"
          class="w-full"
          name="NumOfChildren"
          type="number"
        />
        <span class="text-danger text-sm" v-show="errors.has('NumOfChildren')">{{
          errors.first("NumOfChildren")
        }}</span>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("PhoneNumber") }}</label>
        <vs-input
          v-model="patientModel.Phone"
          class="w-full"
          name="Phone"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Phone')">{{
          errors.first("Phone")
        }}</span>
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("OtherPhone") }}</label>
        <vs-input v-model="patientModel.OtherPhone" class="w-full" name="OtherPhone" />
      </div>
      <div class="vx-col lg:w-1/4 w-full">
        <vs-card title="icon" class="mt-5">
          <h4>{{ $t("Image") }}</h4>
          <template v-if="patientModel.ProfileImagePath">
            <div class="img-container w-64 mx-auto flex items-center justify-center">
              <img
                :src="baseURL + patientModel.ProfileImagePath"
                alt="img"
                class="responsive"
              />
            </div>
          </template>
          <div class="modify-img flex justify-between mt-5">
            <input
              type="file"
              class="hidden"
              ref="updateImgInput"
              @change="updateCurrImg"
              accept="image/*"
            />
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{
              $t("UpdateImage")
            }}</vs-button>
            <vs-button
              type="flat"
              color="#999"
              @click="patientModel.ProfileImagePath = null"
              >{{ $t("RemoveImage") }}</vs-button
            >
          </div>
        </vs-card>
      </div>
    </div>

    <vx-card style="margin-top: 10px" v-if="false">
      <h1>Related Users</h1>
      <br />
      <vs-table ref="table" pagination :max-items="5" :data="patientModel.KinshipUsers">
        <template slot="thead">
          <vs-th> {{ $t("Name") }}</vs-th>
          <vs-th> {{ $t("Email") }}</vs-th>
          <vs-th> {{ $t("Is Active") }}</vs-th>
          <vs-th> {{ $t("Kinship Type") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.FullName }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.Email }}
                </p>
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.IsActive }}
                </p>
              </vs-td>
              <vs-td>
                <p v-if="tr.KinshipType" class="product-name font-medium truncate">
                  {{ tr.KinshipType.Name }}
                </p>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleGender from "@/store/settings/gender/moduleGender.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import moduleCity from "@/store/settings/city/moduleCity.js";
import Datepicker from "vuejs-datepicker";
import moduleRegion from "@/store/settings/region/moduleRegion.js";

export default {
  components: {
    vSelect,
    Datepicker,
  },
  props: {
    patientModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    countries() {
      return this.$store.state.CountryList.countries;
    },
    genders() {
      return this.$store.state.GenderList.genders;
    },
    regions() {
      return this.$store.state.RegionList.regions;
    },
    cities() {
      return this.$store.state.CityList.cities;
    },
  },
  data() {
    return {
      isMounted: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile",
    };
  },
  methods: {
    updateCurrImg(input) {
      this.$store.dispatch("updateCurrImg", input).then((response) => {
        this.patientModel.ProfileImagePath = response.data;
      });
    },
    getRegionByCountryId(CountryId) {
      debugger;
      this.$store.dispatch("RegionList/GetAllRegionsByCountryID", CountryId).then(() => {
        debugger;
        if (this.patientModel.RegionID > 0) {
          debugger;
          this.getCityByRegionId(this.patientModel.RegionID);
        }
      });
    },
    getCityByRegionId(RegionId) {
      debugger;
      this.$store.dispatch("CityList/GetAllCitiesByRegionID", RegionId);
    },
  },
  created() {
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleGender.isRegistered) {
      this.$store.registerModule("GenderList", moduleGender);
      moduleGender.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }

    if (!moduleRegion.isRegistered) {
      this.$store.registerModule("RegionList", moduleRegion);
      moduleRegion.isRegistered = true;
    }
    this.$store.dispatch("RegionList/GetAllRegions");

    this.$store.dispatch("CityList/GetAllCities");
    this.$store.dispatch("LanguageList/GetAllLanguages");
    this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    this.$store.dispatch("GenderList/GetAllGenders");
    this.$store.dispatch("CountryList/GetAllCountries");
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
